.header-navbar {
    padding: 0px;
    min-height: 60px;
    // font-family: $font-family-monospace;
    transition: 300ms ease all;
    background: #fff;
    z-index: 1000;
    &.fixed-top.left-space {
        left: $menu-expanded-width;
    }

    .dropdown-user {
        img {
            height: 36px;
            width: 36px;
            border-radius: 50%;
        }

        .dropdown-menu {
            padding: 0.5rem;
            border: 1px solid rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
            &::before {
                content: '';
                position: absolute;
                top: -1px;
                width: 0.75rem;
                height: 0.75rem;
                display: block;
                z-index: 10;
                box-sizing: border-box;
                background: #fff;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                transform: rotate(45deg) translate(-7px);
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                right: 0.6rem;
                left: auto;
            }

            .dropdown-item {
                padding: 10px;

                &:active,
                &:hover {
                    background-color: $primary;
                    color: #fff;
                }
            }

            .user-info {
                padding: 10px;
                white-space: nowrap;
            }
        }
    }

    .dropdown-notification {
        .nav-link-label {
            position: relative;
            display: flex;
            height: 100%;
            align-items: center;
            padding: 8px 24px;

            .badge-up {
                position: absolute;
                top: 7px;
                left: 35px;
                padding: 0.42em 0.6em 0.25rem;
            }
        }
        .notification-text {
            margin-bottom: 0.25rem;
            font-size: smaller;
            color: $body-color;
        }
        .notification-title {
            color: rgba($white, 0.75);
        }

        .notification-tag {
            position: relative;
            top: -4px;
        }
        .dropdown-menu.dropdown-menu-right {
            right: -2px;
            padding: 0;
            &::before {
                background: $primary;
                border-color: $primary;
            }
        }
        .dropdown-menu-header {
            border-top-left-radius: $dropdown-border-radius;
            border-top-right-radius: $dropdown-border-radius;
            background: $primary;
            color: $white;
            text-align: center;
            .dropdown-header h3 {
                margin-bottom: 0.25rem;
            }
        }
    }
}
