.status-btn {
    // font-size: 14px;
    color: #000000;
    background-color: rgba($color: $primary, $alpha: 0.1);
    // border: none;
    // line-height: 16px;
    // padding: 12px;
    // border-radius: 7px;

    &.status-dropdown {
        padding-right: 36px;
        background-image: url(str-replace(
            str-replace($chevron-down, 'currentColor', #000000),
            '#',
            '%23'
        ));
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }

    &:active,
    &:focus,
    &:hover,
    &:disabled {
        box-shadow: none !important;
        outline: none !important;
        background-color: rgba($color: $primary, $alpha: 0.1);
        color: #000000;
    }

    &.blue-light {
        background-color: #e8e6f3;

        &:active,
        &:focus,
        &:hover,
        &:disabled {
            background-color: #e8e6f3;
        }
    }
}

.btn-group-primary {
    .btn {
        background-color: rgba($color: $primary, $alpha: 0.1);
        color: #000000;
        border: 0;
        font-size: 14px;
        font-weight: normal;
        box-shadow: none !important;
        outline: 0 !important;

        &.active {
            background-color: $primary;
            color: #ffffff;
        }
    }
}

.multilevl-dropdown {
    .dropdownButton {
        padding: 5px !important;
        height: auto !important;
        border-radius: 100% !important;
    }

    .submenu {
        min-width: 300px;
    }
}

.btn-lightgreen {
    background-color: #e2eee2;
    border-color: #e2eee2;
    color: #0d7511;
    box-shadow: none !important;
    outline: none !important;

    &:hover {
        background-color: #e2eee2;
        border-color: #e2eee2;
        color: #0d7511;
    }

    &:active {
        background-color: #e2eee2;
        border-color: #e2eee2;
        color: #0d7511;
    }

    &:focus {
        background-color: #e2eee2;
        border-color: #e2eee2;
        color: #0d7511;
    }
}

.btn-white {
    background-color: #ffffff !important;
    color: $primary !important;

    &:hover {
        background-color: #ffffff !important;
        color: $primary !important;
    }

    &:active {
        background-color: #ffffff !important;
        color: $primary !important;
    }

    &:focus {
        background-color: #ffffff !important;
        color: $primary !important;
    }
}

.btn-white-elc {
    background-color: #ffffff !important;
    color: $green !important;

    &:hover {
        background-color: #ffffff !important;
        color: $green !important;
    }

    &:active {
        background-color: #ffffff !important;
        color: $green !important;
    }

    &:focus {
        background-color: #ffffff !important;
        color: $green !important;
    }
}

.btn-light-danger {
    background-color: #fbdddc !important;
    color: #c22725 !important;

    &:hover {
        background-color: #fbdddc !important;
        color: #c22725 !important;
    }

    &:active {
        background-color: #fbdddc !important;
        color: #c22725 !important;
    }

    &:focus {
        background-color: #fbdddc !important;
        color: #c22725 !important;
    }
}

.btn-blue {
    background-color: #dfedff !important;
    color: #09469f !important;

    &:hover {
        background-color: #dfedff !important;
        color: #09469f !important;
    }

    &:active {
        background-color: #dfedff !important;
        color: #09469f !important;
    }

    &:focus {
        background-color: #dfedff !important;
        color: #09469f !important;
    }
}

.btn-lightblue {
    background-color: #e8e6f3 !important;
    color: $primary !important;

    &:hover {
        background-color: #e8e6f3 !important;
        color: $primary !important;
    }

    &:active {
        background-color: #e8e6f3 !important;
        color: $primary !important;
    }

    &:focus {
        background-color: #e8e6f3 !important;
        color: $primary !important;
    }
}

.btn-acba {
    background-color: #c41e3a;
    color: #ffffff;

    &:hover {
        background-color: #c41e3a;
        color: #ffffff;
    }

    &:active {
        background-color: #c41e3a;
        color: #ffffff;
    }

    &:focus {
        background-color: #c41e3a;
        color: #ffffff;
    }
}

.btn-scba {
    background-color: #309c8a !important;
    color: #ffffff !important;

    &:hover {
        background-color: #309c8a !important;
        color: #ffffff !important;
    }

    &:active {
        background-color: #309c8a !important;
        color: #ffffff !important;
    }

    &:focus {
        background-color: #309c8a !important;
        color: #ffffff !important;
    }
}

.btn-gray {
    background-color: #e8e8e8 !important;
    color: #5a5a5a !important;

    &:hover {
        background-color: #e8e8e8 !important;
        color: #5a5a5a !important;
    }

    &:active {
        background-color: #e8e8e8 !important;
        color: #5a5a5a !important;
    }

    &:focus {
        background-color: #e8e8e8 !important;
        color: #5a5a5a !important;
    }
}

.btn-v2 {
    background-color: #f2f2f2 !important;
    color: #343a40 !important;

    &:hover {
        background-color: #f2f2f2 !important;
        color: #343a40 !important;
    }

    &:active {
        background-color: #f2f2f2 !important;
        color: #343a40 !important;
    }

    &:focus {
        background-color: #f2f2f2 !important;
        color: #343a40 !important;
    }
}

.btn-status-conacted {
    background-color: #dfedff;
    border-color: #dfedff;
    color: #09469f;
    box-shadow: none !important;
    outline: none !important;

    &:hover {
        background-color: #dfedff;
        border-color: #dfedff;
        color: #09469f;
    }

    &:active {
        background-color: #dfedff;
        border-color: #dfedff;
        color: #09469f;
    }

    &:focus {
        background-color: #dfedff;
        border-color: #dfedff;
        color: #09469f;
    }
}

.btn-status-open {
    background-color: #e2eee2;
    border-color: #e2eee2;
    color: #0d7511;
    box-shadow: none !important;
    outline: none !important;

    &:hover {
        background-color: #e2eee2;
        border-color: #e2eee2;
        color: #0d7511;
    }

    &:active {
        background-color: #e2eee2;
        border-color: #e2eee2;
        color: #0d7511;
    }

    &:focus {
        background-color: #e2eee2;
        border-color: #e2eee2;
        color: #0d7511;
    }
}

.btn-status-hold {
    background-color: #e8e6f3;
    border-color: #e8e6f3;
    color: #220d89;
    box-shadow: none !important;
    outline: none !important;

    &:hover {
        background-color: #e8e6f3;
        border-color: #e8e6f3;
        color: #220d89;
    }

    &:active {
        background-color: #e8e6f3;
        border-color: #e8e6f3;
        color: #220d89;
    }

    &:focus {
        background-color: #e8e6f3;
        border-color: #e8e6f3;
        color: #220d89;
    }
}

.btn-status-closed {
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    color: #5a5a5a;
    box-shadow: none !important;
    outline: none !important;

    &:hover {
        background-color: #e8e8e8;
        border-color: #e8e8e8;
        color: #5a5a5a;
    }

    &:active {
        background-color: #e8e8e8;
        border-color: #e8e8e8;
        color: #5a5a5a;
    }

    &:focus {
        background-color: #e8e8e8;
        border-color: #e8e8e8;
        color: #5a5a5a;
    }
}
