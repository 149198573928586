.miller-column-wrapper {
    .list-group-item {
        font-size: 85%;
        padding-left: 8px;
        padding-right: 8px;

        &.expanded {
            color: $primary;
            background-color: #e8e6f3;
            border-color: #e8e6f3;
        }

        &.selected {
            color: #fff;
            background-color: #220c89;
            border-color: #220c89;
        }

        &.notAllowed {
            cursor: not-allowed;
            color: #6c757d;
            //pointer-events: none;
            background-color: #fff;
        }
    }
}
