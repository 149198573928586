// Import first main menu mixin
@import './mixins/main-menu-mixin';

// Main menu base
//==============
.main-menu {
    z-index: 1031;
    position: absolute;
    display: table-cell;
    height: 100%;
    overflow: hidden;
    width: $menu-expanded-width;
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    // &.menu-light {
    // 	@include menu-theme($body-color, $white);
    // }

    &.space-top {
        top: 60px;
    }

    &.menu-dark {
        @include menu-theme(#fff, #12142a);

        &.space-top {
            .navigation li .menu-text {
                width: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;

                svg {
                    margin: 0;
                    margin-bottom: 4px;
                }

                .menu-item {
                    font-size: 13px;
                }
            }
        }
    }

    &.menu-fixed {
        position: fixed;
        /* fallback if needed */
        // top: $navbar-height;
        .main-menu-footer {
            position: fixed;
        }
    }

    &.menu-static {
        height: auto;
        padding-bottom: calc(100% - 35rem);
        .main-menu-content {
            height: unset !important;
        }
    }

    &.menu-shadow {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    }

    &.menu-border {
        border-right: 1px solid $custom-border-color;
    }

    // menu scroll shadow
    .shadow-bottom {
        position: absolute;
        z-index: 2;
        height: 60px;
        width: 100%;
        pointer-events: none;
        margin-top: -1.3rem;
        margin-left: -1rem;
        filter: blur(5px);
        background: linear-gradient(
            #fff 41%,
            hsla(0, 0%, 100%, 0.11) 95%,
            hsla(0, 0%, 100%, 0)
        );
    }

    // Native Scrollbar (.menu-native-scroll)
    &.menu-native-scroll {
        .main-menu-content {
            overflow-y: scroll;
        }
    }

    .navbar-header {
        // height: 100%;
        width: $menu-expanded-width;
        // height: $navbar-height;
        position: relative;
        padding: 14px 13px;
        transition: 300ms ease all;
        .navbar-brand {
            // padding: 15px 0px;
            // display: flex;
            // align-items: center;
            // margin-top: 1.35rem;
            display: inline-block;
            .brand-logo {
                //background: url("../../../img/logo/vuesax-logo.png") no-repeat;
                background-position: -65px -54px;
                height: 24px;
                width: 35px;
            }

            .brand-text {
                color: $primary;
                padding-left: 1rem;
                font-weight: 600;
                letter-spacing: 0.01rem;
                font-size: 1.57rem;
                animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal
                    forwards 1 fadein;
            }
        }
        .modern-nav-toggle {
            animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards
                1 fadein;
        }
    }

    .main-menu-content {
        height: calc(100% - 6rem) !important;
        position: relative;
        transition: all 0.25s ease;
        // margin-top: 0.45rem;
        .ps__rail-y:hover,
        &.ps--active-y .ps__rail-y {
            background-color: transparent !important;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &.navigation-main {
            overflow-x: hidden;
            > li:first-child {
                margin-top: 0.5rem;
            }
            .nav-item {
                svg:first-child {
                    margin-right: 1rem;
                    position: relative;
                    top: -1px;
                }
                svg.menu-toggle-icon {
                    position: absolute;
                    right: 22px;
                    top: 15px;
                    transition: all 0.25s ease;
                }
            }
        }
    }

    a {
        outline: none;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &.hide-sidebar {
        transform: translateX(-100%);
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    &:not(.hide-sidebar) {
        transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
}

// Main Navigation
.navigation {
    // font-size: 1.1rem;
    // font-family: $font-family-monospace;
    // font-weight: 400;
    // overflow-y: hidden;
    // padding-bottom: 20px;

    .navigation-header {
        font-family: $font-family-monospace;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        padding: 12px 22px;
        font-size: 0.9rem;
        text-transform: uppercase;
    }

    li {
        position: relative;
        white-space: nowrap;

        a {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &.disabled {
            pointer-events: none;
            cursor: not-allowed;
        }
    }
    .nav-item {
        &.active {
            a {
                background: linear-gradient(
                    118deg,
                    rgba($primary, 1),
                    rgba($primary, 0.7)
                );
                box-shadow: 0 0 10px 1px rgba($primary, 0.7) !important;
            }
        }
        .menu-content {
            li.active {
                background: linear-gradient(
                    118deg,
                    rgba($primary, 1),
                    rgba($primary, 0.7)
                );
                box-shadow: 0 0 10px 1px rgba($primary, 0.7) !important;
            }
        }
    }
}
