.anonymous-layout {
    .conference {
        padding-top: 90px;
    }
}

.conference {
    padding-bottom: 50px;
    // .localPreviewContainer {
    .video-container {
        height: 0;
        overflow: hidden;
        position: relative;
        background: black;
        padding-top: 56.25%;
    }

    .video-innercontainer {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        video {
            height: 100%;
            width: 100%;
        }

        .avatarContainer {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            z-index: 1;
            position: absolute;
            background: black;
            align-items: center;
            justify-content: center;
        }
    }

    .identityContainer {
        bottom: 0;
        z-index: 1;
        width: 100%;
        position: absolute;

        .identity {
            color: white;
            margin: 0;
            display: flex;
            padding: 0.18em 0.3em;
            background: rgba(0, 0, 0, 0.5);
            align-items: center;
        }
    }
    // }

    .background-setting {
        width: 400px;
        z-index: 1051;
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        right: -400px;

        &.open {
            right: 0;
        }

        .thumbnailContainer {
            display: flex;
            padding: 5px;
            flex-wrap: wrap;
            overflow-y: auto;
        }

        .thumbContainer {
            width: calc(50% - 10px);
            margin: 5px;
            display: flex;
            position: relative;

            &::after {
                content: '';
                padding-bottom: 55.5%;
            }
        }

        .thumbImage {
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            border: solid #bdbdbd;
            bottom: 0;
            height: 100%;
            position: absolute;
            object-fit: cover;
            border-radius: 10px;

            &.selected {
                border: solid $primary;
            }
        }

        .thumbIconContainer {
            width: 100%;
            border: solid #bdbdbd;
            display: flex;
            align-items: center;
            border-radius: 10px;
            justify-content: center;

            &.selected {
                border: solid $primary;
            }

            svg {
                width: 50px;
                height: 50px;
            }
        }

        .thumbOverlay {
            color: transparent;
            width: 100%;
            height: 100%;
            display: flex;
            padding: 20px;
            position: absolute;
            font-size: 14px;
            align-items: center;
            font-weight: bold;
            border-radius: 10px;
            justify-content: center;

            &:hover {
                color: white;
                background: rgba(95, 93, 128, 0.6);
            }
        }
    }
}

video {
    height: 100%;
    width: 100%;
}
