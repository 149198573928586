.qase-wizard {
    box-shadow: none !important;
    margin-bottom: 38px;

    &.nav.nav-tabs {
        border: none;

        .step-wrapper.nav-item {
            flex: 1 0 0%;

            .step.nav-link {
                transform: translateY(0) !important;
                text-align: center !important;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                flex-direction: column;
                padding: 0;

                &:before {
                    background: #e8e8e8;
                    box-shadow: none;
                    height: 2px;
                    z-index: -1;
                    width: 50%;
                    content: '';
                    position: absolute;
                    top: 11px;
                    left: 0;
                }

                &:after {
                    background: #e8e8e8;
                    box-shadow: none;
                    height: 2px;
                    z-index: -1;
                    width: 50%;
                    content: '';
                    position: absolute;
                    top: 11px;
                    right: 0;
                }

                .wizard-circle {
                    border-radius: 50%;
                    font-size: 1.5rem;
                    z-index: 2;
                    height: 24px;
                    width: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $gray-600;
                    margin-bottom: 16px;
                }

                .wizard-title {
                    margin: 0;
                    font-size: 16px;
                    color: #333333;
                }

                &.active {
                    .wizard-circle {
                        background-color: $primary;
                        color: $white;

                        &.elc {
                            background-color: #005454;
                        }
                    }

                    .wizard-title {
                        color: $primary;
                        font-weight: 500;

                        &.elc {
                            color: #005454;
                        }
                    }

                    &:before {
                        background-color: $primary;
                    }

                    &.elc {
                        &:before {
                            background-color: #005454;
                        }
                    }
                }

                &.done {
                    .wizard-circle {
                        background-color: $primary;

                        &.elc {
                            background-color: #005454;
                        }
                    }

                    .wizard-title {
                        color: $primary;
                        font-weight: 500;

                        &.elc {
                            color: #005454;
                        }
                    }

                    &:before,
                    &:after {
                        background-color: $primary;
                    }

                    &.elc {
                        &:before,
                        &:after {
                            background-color: #005454;
                        }
                    }
                }
            }

            &:first-child {
                .step.nav-link:before {
                    content: none;
                }
            }

            &:last-child {
                .step.nav-link:after {
                    content: none;
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    .qase-wizard {
        &.nav.nav-tabs {
            .step-wrapper.nav-item {
                .step.nav-link {
                    &:before {
                        top: 7.5px;
                    }

                    &:after {
                        top: 7.5px;
                    }

                    .wizard-circle {
                        height: 18px;
                        width: 18px;
                    }

                    .wizard-title {
                        font-size: 12px;
                    }
                }

                &:first-child {
                    .step.nav-link:before {
                        content: none;
                    }
                }

                &:last-child {
                    .step.nav-link:after {
                        content: none;
                    }
                }
            }
        }
    }
}
