html {
    body {
        height: 100%;
        background-color: rgba(245, 247, 249, 1);

        .content {
            padding: 0;
            position: relative;
            transition: 300ms ease all;
            backface-visibility: hidden;
            min-height: calc(100% - 4rem);
            margin-left: 0;

            &.has-sidebar {
                margin-left: $menu-expanded-width;
            }

            &.app-content {
                overflow: hidden;
                min-height: 100vh;
                background-color: #f0f6ff;

                &.show-overlay {
                    .content-overlay {
                        z-index: 10;
                        opacity: 1;

                        ~ .header-navbar-shadow {
                            background: linear-gradient(
                                180deg,
                                rgba(44, 48, 60, 0.9) 44%,
                                rgba(44, 48, 60, 0.43) 73%,
                                rgba(44, 48, 60, 0)
                            );
                        }
                    }
                }
            }

            .body-content-overlay {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                position: absolute;
                display: block;
                z-index: 4;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease;
                &.show {
                    visibility: visible;
                    transition: all 0.3s ease;
                    opacity: 1;
                    background-color: rgba($black, 0.2);
                    border-radius: calc($border-radius/2);
                }
            }
        }

        // for sticky navbar
        .vertical-layout {
            &.menu-collapsed {
                .header-navbar.fixed-top.left-space {
                    left: $menu-collapsed-width;
                    width: auto;
                }
            }

            &.navbar-sticky {
                .app-content {
                    .header-navbar-shadow {
                        display: none;
                    }

                    .navbar-container {
                        padding-left: 2.2rem;
                        padding-right: 1rem;
                    }

                    .content-wrapper {
                        padding: 14px 13px 14px 15px;
                        margin-top: 60px; //4.65rem;
                    }
                }
            }
        }

        // navbar search width for sticky and static navbar
        .vertical-layout.navbar-static,
        .vertical-layout.navbar-sticky {
            .navbar-container {
                padding-left: 2.2rem;
                padding-right: 1rem;
            }
        }

        .vertical-layout .menu-swipe-area {
            width: 30px;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 1032;
            height: 100vh;
        }
    }
}

.vertical-layout {
    .main-menu {
        .navigation {
            .menu-content {
                li a {
                    svg:first-child {
                        margin-right: 1.38rem !important;
                    }

                    .menu-toggle-icon {
                        right: 9px;
                    }
                }
            }
        }
    }

    &.menu-collapsed {
        .main-menu.collapsed {
            width: $menu-collapsed-width;
            transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

            &.space-top {
                width: $menu-partial-collapsed-width;
            }

            .badge,
            svg.menu-toggle-icon,
            .brand-text,
            .navigation-header,
            ul.menu-content {
                display: none;
            }
        }

        .app-content {
            margin-left: $menu-collapsed-width;

            // .header-navbar {
            //     width: calc(100% - 4.4rem - 80px);
            // }
        }

        .footer {
            margin-left: $menu-collapsed-width;
        }

        &.partial-collapsed {
            .app-content {
                margin-left: $menu-partial-collapsed-width;
            }
        }
    }
}

.login-page-wrapper,
.register-page-wrapper {
    padding-top: 152px;
    padding-bottom: 151px;

    .card {
        border-radius: 0;
        box-shadow: 0px 0px 10px rgba(34, 13, 137, 0.1);

        &.left-side {
            .card-body {
                padding: 52px 32px 74px;
            }
        }
        &.right-side {
            .card-body {
                padding: 34px 29px 74px;
            }
        }
    }
}

.accept_or_decline_wrapper {
    // min-height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: #f0f6ff;
    // background-image: url(../images/backgrounds/wave1.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    background-position: bottom -800px right 50%;

    .card {
        box-shadow: 0 2px 14px 10px rgba(0, 0, 0, 0.05);
        border-radius: 0;

        .card-body {
            padding: 16px 24px;
        }

        .confirm-wrapper {
            padding-top: 60px;
            padding-bottom: 76px;
            text-align: center;
        }
    }
}

.confernece-wrapper {
    padding-top: 128px;
    padding-bottom: 104px;
    background-color: #f0f6ff;
    min-height: 100vh;
    .card.box-shadow {
        box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.22);
    }
}

.footer {
    padding-top: 33px;
    padding-bottom: 24px;
    background-color: #121529;
    color: #fff;
    
    background-repeat: no-repeat;
    background-position: left center;

    &.qase{
        background-image: url(../images//logo/Qase-Logo-Brandmark.png);
    }

    &.elc-footer {
        background-color: #005454;
        background-image: none;
    }

    &.acba-footer {
        background-color: #c41e3a;
        background-image: none;
    }

    a {
        color: #ffffff;
    }

    .footer-logo {
        margin-bottom: 24px;

        img {
            height: 48px;
        }
    }

    .social-links {
        a {
            margin-left: 12px;
            margin-right: 12px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                color: $primary;
            }
        }
    }
}

.collapse-toggle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: rgba(216, 216, 216, 0.3);
    display: inline-block;
    position: relative;
    cursor: pointer;

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.qase-dropdown-tree {
    position: relative;
    box-sizing: border-box;

    .dropdown {
        display: block;

        .dropdown-trigger {
            display: block;
            border-radius: 4px;
            background-color: #f2f2f2;
            border-color: #f2f2f2;
            min-height: 38px;

            .tag-list {
                .tag-item {
                    margin: 0;

                    .placeholder {
                        cursor: pointer;
                        background-color: transparent;
                    }
                }
            }

            &[aria-labelledby] {
                .tag-list {
                    .tag-item {
                        margin: 0;

                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }

        .dropdown-content {
            width: 100%;
            margin-top: 5px;
            border-radius: 4px;

            .search {
                padding: 0.375rem 0.75rem;
            }

            .infinite-scroll-component {
                max-height: 450px;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 8px;
                    background-color: #aaa; /* or add it to the track */
                }

                &::-webkit-scrollbar-thumb {
                    background: $primary;
                }
            }
        }
    }
}

.qase-loading {
    margin: 0 auto;
    background: url('../images/Qase-Loading-Animation-v1.gif') no-repeat center
        center;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    background-color: rgba(250, 250, 250, 0.8);
    z-index: 10000000;

    &.fixed {
        position: fixed;
    }
}

.dropzone {
    display: flex;
    cursor: pointer;
    min-height: 300px;
    align-items: center;
    justify-content: center;
    background-color: rgba(34, 13, 137, 0.05);
    border: 1.5px dashed #220d89;
    border-radius: 10px;

    &.dropzone-v1 {
        min-height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.tox.tox-tinymce {
    border: 0;
    border-radius: 0;

    .tox-editor-header {
        border: 1px solid #e9e9e9;
    }
}

.max-w-140 {
    max-width: 140px;
}

.max-w-161 {
    max-width: 161px;
}

.mw-550 {
    max-width: 550px;
}

.q-fs-10 {
    font-size: 10px;
}

.q-fs-11 {
    font-size: 11px;
}

.q-fs-12 {
    font-size: 12px;
}

.q-fs-14 {
    font-size: 14px;
}

.q-fs-16 {
    font-size: 16px;
}

.q-fs-18 {
    font-size: 18px;
}

.q-fs-20 {
    font-size: 20px;
}

.q-fs-22 {
    font-size: 22px;
}

.q-fs-24 {
    font-size: 24px;
}

.q-fs-28 {
    font-size: 28px;
}

.q-fs-32 {
    font-size: 32px;
}

.q-fs-36 {
    font-size: 36px;
}

.q-fw-400 {
    font-weight: 400;
}

.q-fw-500 {
    font-weight: 500;
}

.q-fw-600 {
    font-weight: 600;
}

.line-height-1 {
    line-height: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.btn {
    //min-width: 106px;
    .hover-text {
        display: none;
    }

    &:hover {
        .hover-text {
            display: inline-block;
        }
    }
}

.primary-toggle-wrapper {
    position: absolute;
    top: 54px;
    left: -10px;

    .collapse-toggle {
        background-color: #e8e6f2;
    }
}

.drag-wrapper {
    min-height: 50px;
    height: 100%;

    .draggable {
        transition: all 0.3s;
        border-radius: 5px;

        .drag-icon {
            //transition: all 0.3s;
            position: absolute;
            right: 6px;
            top: 6px;
            //display: none;
            opacity: 0;
        }

        &:hover {
            cursor: pointer;
            //background-color: #e8e6f2;
            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);

            .drag-item-title {
                color: $primary;

                &.underline {
                    text-decoration: underline;
                }
            }

            .drag-icon {
                // display: block;
                opacity: 1;
            }
        }
    }
}

/* STRIPE */
.StripeElement.v-1 {
    padding: 0.6rem 0.75rem;
    border-bottom: 1px solid #ced4da;

    &.StripeElement--focus {
        border-color: $primary;
    }
}

.StripeElement.v-2 {
    padding: 0.6rem 0.75rem;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 0.25rem;

    &.StripeElement--focus {
        border-color: $primary;
    }
}

.profile-content {
    .h5 {
        font-size: 20px;
        font-weight: 600;
    }

    .card-header {
        padding: 16px 26px 16px 53px;

        .btn {
            min-width: 106px;
            padding: 11px 14px;
            font-size: 14px;
            line-height: 16px;
        }
    }

    // .card-body {
    //     padding-left: 53px;
    //     padding-right: 53px;
    // }
}

.profile-picture-wrapper {
    position: relative;

    img {
        width: 140px;
        border-radius: 50%;
    }

    .upload-profile-picture-wrapper {
        position: absolute;
        top: 41px;
        right: -15px;
        cursor: pointer;
        background-color: $primary;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#ReactSimpleImageViewer {
    z-index: 11111111111;
}

.profile-navigation-wrapper {
    padding: 15px;
}

@media (min-width: 768px) {
    .accept_or_decline_wrapper {
        padding-top: 152px;
        padding-bottom: 152px;
    }

    .q-fs-md-18 {
        font-size: 18px;
    }

    .q-fs-md-36 {
        font-size: 36px;
    }

    .profile-navigation-wrapper {
        padding: 35px 35px 35px 16px;
        border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
    }

    .profile-content {
        .card-body {
            padding-left: 53px;
            padding-right: 53px;
        }
    }
}

@media (min-width: 768px) {
    .minwith-536-md {
        min-width: 536px;
    }
}

@media (min-width: 992px) {

    .minwith-811-md {
        min-width: 811px;
    }

    .q-modal-lg {
        min-width: 882px;
    }

    .q-modal-md {
        min-width: 588px;
    }
}

@include media-breakpoint-down(md) {
    html body .app-content .content-wrapper {
        padding: 14px !important;
    }
}

.tooltip-v1-auto {
    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        background-color: #5a5a5a;
        text-align: left;
    }
}

.tooltip-v2-auto {
    &.show {
        opacity: 1;
    }
    .tooltip-inner {
        background-color: #fff;
        text-align: left;
        color: #220d89;
        border: 1px solid;
    }
}

.checkbox-dropdown-scroll {
    max-height: 400px;
    overflow-y: auto;
    min-width: 200px;
}
