.qase-tab-v1 {
    border-bottom: 2px solid #e2e0eb;

    .nav-link {
        margin-bottom: -2px;
        color: #85919d;
        transition: all 0.2ms;
        border: none;
        border-bottom: 2px solid transparent;
        &.active,
        &:hover {
            color: $primary;
            border: none;
            border-bottom: 2px solid $primary;
        }
    }
}

.qase-tab-v2 {
    border-bottom: 2px solid #e2e0eb;

    .nav-link {
        margin-bottom: -2px;
        color: #85919d;
        transition: all 0.2ms;
        border: none;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        padding: 16px 20px 18px;

        &.active {
            color: $primary;
            border-color: transparent;
            background-color: transparent;
            border-bottom-color: $primary;
            // border: none;
            // border-bottom: 2px solid $primary;
            // background-color: transparent;
        }
        &:hover {
            color: $primary;
            border-color: transparent;
            border-bottom-color: $primary;
        }
    }
}

.qase-tab-v3 {
    border-bottom: 2px solid $primary;
    background-color: $primary;

    .nav-link {
        margin-bottom: 0;
        color: rgba($color: #ffffff, $alpha: 0.6);
        transition: all 0.2ms;
        border: none;
        border-bottom: 2px solid transparent;
        border-radius: 0;
        padding: 16px 20px 18px;

        &.active {
            color: #ffffff;
            border-color: transparent;
            background-color: transparent;
            border-bottom-color: #ffffff;
            // border: none;
            // border-bottom: 2px solid $primary;
            // background-color: transparent;
        }
        &:hover {
            color: #ffffff;
            border-color: transparent;
            border-bottom-color: #ffffff;
        }
    }
}

.qase-pills-v1 {
    .nav-link {
        background: #f5f7f9;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        height: 100%;
        border-radius: 6px;
        padding: 12px 41px;
        &.active {
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.qase-pills-vertical-v1 {
    .nav-link {
        font-size: 16px;
        font-weight: 700;
        color: rgba($color: #000000, $alpha: 0.6);
        padding: 20px 30px;
        border-radius: 54px;
        margin-bottom: 10px;

        .nav-icon {
            display: none;
        }

        &.active {
            .nav-icon {
                display: block;
            }
        }

        &:hover {
            background-color: $primary;
            color: #ffffff;

            .nav-icon {
                display: block;
            }
        }
    }
}
