$base-font-size: 14px;

$menu-expanded-width: 250px;
$menu-collapsed-width: 56px;
$menu-partial-collapsed-width: 116px;
$content-padding: 2.2rem;

//  ------------------------------
//    Colors
//  ------------------------------
$pure-black: #000;
$nav-component-border-color: #ddd;
$custom-border-color: #e4e7ed;
$chip-bg-color: #f0f0f0;
$chip-avatar-bg: #c3c3c3;
$avatar-bg: $chip-avatar-bg;
$swiper-bg: #f2f4f4;
$hover-color: #eee;

//  ------------------------------
//    Navbar
//  ------------------------------
$navbar-height: 60px;
$floating-nav-margin: 1.3rem;
//  ------------------------------
//    Main Menu
//  ------------------------------
$menu-padding: 10px 15px;
$menu-second-level-padding: 10px 15px 10px 20px;
$menu-third-level-padding: 10px 15px 10px 20px;
$menu-forth-level-padding: 10px 15px 10px 30px;

$input-group-addon-bg: #fff !default;

//  -------------------------------
//    Avatar
//  -------------------------------

$avatar-size: 34px !default;
$avatar-status-size: 11px !default;
$avatar-status-size-lg: 17px !default;

$avatar-size-xl: 70px !default;
$avatar-size-lg: 50px !default;
$avatar-size-sm: 24px !default;

$avatar-bg: #c3c3c3;
$avatar-group-border: #fff;
