.q-color-gray {
    color: #8f8f8f;
}

.q-color-grey {
    color: #5a5a5a;
}

.q-color-gray-2 {
    color: #828282;
}

.q-color-darkgray {
    color: #aab4be;
}

.q-color-dimgray {
    color: #5c5c5c;
}

.q-color-darkslategray {
    color: #454446;
}

.q-color-lightslategray {
    color: #85919d;
}

.q-color-lightseagreen {
    color: #00cc99;
}

.q-color-versegreen {
    color: #0d7511;
}

.bg-dark {
    background-color: #333333;
}

.q-bg-whitesmoke {
    background-color: #f8f8f8;
}

.q-bg-whitesmoke2 {
    background-color: #f3f3f3;
}

.q-bg-lightblue {
    background-color: #e9e7f3;
}

.bg-primary-light {
    background-color: #867ac7 !important;
}

.q-bg-gray-6 {
    background-color: #f2f2f2;
}

.q-color-primary--hover:hover {
    color: $primary;
}

.q-underline--hover:hover {
    text-decoration: underline;
}

.q-bg-elc {
    background-color: #005454;
}

.q-border-elc {
    border-color: #005454;
}

.q-color-elc {
    color: #005454;
}

.q-bg-blue-light {
    background-color: #e8e6f3;
}

.q-brd-grey {
    border-color: #5a5a5a;
}
