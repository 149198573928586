/* enable absolute positioning */
.inner-addon {
    position: relative;

    .glyphicon {
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%);
    }

    &.right-addon {
        input {
            padding-right: 30px;
        }

        .glyphicon {
            right: 0;
        }
    }

    &.left-addon {
        input {
            padding-left: 30px;
        }

        .glyphicon {
            left: 0;
        }
    }
}

// input group !important for overriding the dark style
.input-group {
    box-shadow: none !important;
    &:not(.bootstrap-touchspin):focus-within {
        box-shadow: $input-focus-box-shadow;
        border-radius: $input-border-radius;
        .form-control,
        .input-group-text {
            border-color: $primary;
            box-shadow: none !important;

            &.is-valid {
                border-color: $success;
            }

            &.is-invalid {
                border-color: $danger;
            }
        }
    }
    &.is-valid {
        .input-group-text {
            border-color: $success !important;
        }
        &:not(.bootstrap-touchspin):focus-within {
            .input-group-text {
                border-color: $success;
            }
        }
    }
    &.is-invalid {
        .input-group-text {
            border-color: $danger !important;
        }
        &:not(.bootstrap-touchspin):focus-within {
            .input-group-text {
                border-color: $danger;
            }
        }
    }

    &.disabled {
        .input-group-text {
            background-color: $input-disabled-bg;
        }
    }

    &.round {
        .form-control,
        .input-group-text,
        &:focus-within {
            @include border-radius(1.5rem);
        }
    }
    &.square {
        .form-control,
        .input-group-text,
        &:focus-within {
            @include border-radius(0);
        }
    }
}

// .input-group-text {
//     @include transition($input-transition);
// }

// input group merge
.input-group-merge {
    .form-control {
        &:not(:first-child) {
            padding-left: 0;
            border-left: 0;
        }

        &:not(:last-child) {
            padding-right: 0;
            border-right: 0;
        }

        &.is-valid {
            .input-group-text {
                border-color: $success;
            }
        }
    }
}

.input-v1 {
    border-radius: 0;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;

    &:focus,
    &:active {
        box-shadow: none !important;
        border-color: $primary;
    }

    &.pl-13::placeholder {
        font-size: 13px;
    }
}

.input-group-text-v1 {
    border-radius: 0;
    border-right: 0;
    border-top: 0;
}

.input-v2 {
    background-color: #f2f2f2;
    border-color: #f2f2f2;

    &:focus,
    &:active {
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        box-shadow: none !important;
    }
}

.input-group-text-v1 {
    border: 0;
    border-bottom: 1px solid rgb(206, 212, 218);
}

.input-group-text-v2 {
    background-color: #f2f2f2;
    border-color: #f2f2f2;

    &.is-invalid {
        border-color: #dc3545;
    }
}

.input-group-text-v2-disabled {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.label-v1 {
    font-size: 10px;
    font-weight: bold;
    color: #8f8f8f;
    text-transform: uppercase;
    margin-bottom: 3px;
}

.label-v2 {
    font-size: 18px;
    font-weight: 600;
    color: $primary;
}

.label-v3 {
    font-size: 12px;
    font-weight: bold;
    color: #4f4f4f;
    text-transform: uppercase;
    margin-bottom: 4px;
}

.label-v4 {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}

.label-v5 {
    font-weight: 500;
    color: #333333;
}

.label-v6 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}

.form-check-reverse {
    padding-right: $form-check-padding-start;
    padding-left: 0;
    text-align: right;

    .form-check-input {
        float: right;
        margin-right: $form-check-padding-start * -1;
        margin-left: 0;
    }
}

.form-switch-v1 {
    padding-left: 97px;

    .form-check-input {
        width: 77px;
        height: 25px;
        margin-left: -97px;
        border-radius: 33px;
        position: relative;
        background-color: #ededed;
        border-color: #ededed;
        background-image: none !important;
        box-shadow: none !important;
        outline: none !important;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -1px;
            width: 35px;
            height: 35px;
            background-color: #d8d8d8;
            border-radius: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            transition-property: left;
            transition-duration: 0.1s;
            transition-timing-function: ease-in;
        }

        &:checked {
            background-color: #4caf50;
            &::after {
                //left: calc(100% - 12px);
                left: auto;
                right: -1px;
            }
        }
    }
}

.form-switch-v1-sm {
    padding-left: 55px;
    .form-check-input {
        width: 44px;
        height: 15px;
        margin-left: -55px;

        &::after {
            width: 20px;
            height: 20px;
            left: -1px;
        }

        &:checked {
            &::after {
                right: -1px;
                left: auto;
            }
        }
    }
}

.form-check-1 {
    padding-left: 0;

    .form-check-input {
        margin-left: 0;
        margin-right: 10px;
        background-color: transparent;
        border-radius: 0;
        border-color: #333333;

        &:checked {
            background-color: $primary;
            background-color: $primary;
        }
    }
}
