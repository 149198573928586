@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$primary: #220c89;
$secondary: #5a5a5a;
$green: #005454;
$gray-100: #e8e8e8;

$font-family-base: 'Roboto', sans-serif;

@import './variables';
@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap-daterangepicker/daterangepicker.css';
@import './content.scss';
@import './sidebar.scss';
@import './input';
@import './anonymousNavbar.scss';
@import './loggedNavbar.scss';
@import './navigation.scss';
@import './wizard.scss';
@import './tab.scss';
@import './table.scss';
@import './cards.scss';
@import './buttons.scss';
@import './conference.scss';
@import './colors';
@import './spacing';
@import './scaleRate.scss';
@import 'millerColumn';
@import 'autoSuggest';
@import 'offCanvas';
@import 'globalSearch';
@import 'treeSelect';

// body {
// 	font-family: "Lato", sans-serif;
// }
a {
    text-decoration: none;
}

a:hover {
    cursor: pointer;
}

.bg-light-badge {
    background-color: rgba(31, 31, 131, 0.15);
    color: #220c89;
    font-size: 13px;
    font-weight: bold;
    padding: 7px 12px;
    border-radius: 24px;
    display: flex;
}

.date-label {
    padding: 4px 8px;
    background-color: #f2f2f2;
    border-radius: 5px;
    font-size: 12px;
    color: #333333;
    display: flex;
    align-items: center;
}

// =========================================================================================
//   File Name: avatar.scss
//   Description: Avatar images.
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuexy React Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

// Component: Avatar
// ========================================================================

// avatar
.avatar {
    white-space: nowrap;
    background-color: $avatar-bg;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    color: $white;
    display: inline-flex;
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
    font-weight: 600;

    // avatar content
    .avatar-content {
        width: $avatar-size;
        height: $avatar-size;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 0.857rem;
        .avatar-icon {
            height: 1rem;
            width: 1rem;
        }
    }
    [class*='avatar-status-'] {
        border-radius: 50%;
        width: $avatar-status-size;
        height: $avatar-status-size;
        position: absolute;
        right: 0;
        bottom: 0;
        border: 1px solid $white;
        &.avatar-status-lg,
        &.avatar-status-xl {
            width: $avatar-status-size-lg;
            height: $avatar-status-size-lg;
            border-width: 2px;
        }
    }
    .avatar-status-online {
        background-color: $success;
    }
    .avatar-status-busy {
        background-color: $danger;
    }
    .avatar-status-away {
        background-color: $warning;
    }
    .avatar-status-offline {
        background-color: $secondary;
    }

    // avatar label with basic size
    // using in FAQ & other pages
    &.avatar-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        width: 42px;
        border-radius: $border-radius;
    }

    // avatar img
    img {
        border-radius: 50%;
    }

    // Size Modifier
    // ========================================================================

    &.avatar-xl {
        font-size: 1.5rem;
        img {
            width: $avatar-size-xl;
            height: $avatar-size-xl;
        }
        .avatar-content {
            height: $avatar-size-xl;
            width: $avatar-size-xl;
            font-size: 2.57rem;
            .avatar-icon,
            i,
            svg {
                height: 3rem;
                width: 3rem;
                font-size: 3rem;
            }
        }
        [class*='avatar-status-'] {
            right: 3px;
            bottom: 0;
        }
    }
    &.avatar-lg {
        font-size: 1.2rem;
        img {
            width: $avatar-size-lg;
            height: $avatar-size-lg;
        }
        .avatar-content {
            width: $avatar-size-lg;
            height: $avatar-size-lg;
            //font-size: 1.714rem;
            .avatar-icon,
            i {
                height: 2rem;
                width: 2rem;
                font-size: 2rem;
            }
            svg {
                height: 24px;
                width: 24px;
                font-size: 24px;
            }
        }
        [class*='avatar-status-'] {
            right: 3px;
            bottom: 1px;
        }
    }

    &.avatar-sm {
        .avatar-content {
            width: $avatar-size-sm;
            height: $avatar-size-sm;
            .avatar-icon,
            i,
            svg {
                height: 1rem;
                width: 1rem;
                font-size: 1rem;
            }
        }
        img {
            width: $avatar-size-sm;
            height: $avatar-size-sm;
        }
        [class*='avatar-status-'] {
            right: -3px;
            bottom: -2px;
        }
    }
}

// Avatar Group SCSS
.avatar-group {
    display: flex;
    justify-content: flex-start;
    .avatar {
        transition: all 0.25s ease;
        img,
        .avatar-content {
            box-shadow: 0 0 0 2px $avatar-group-border,
                inset 0 0 0 1px rgba($black, 0.07);
        }
        .avatar-content {
            background-color: tint-color($secondary, 20%) !important;
        }
        &:hover {
            transition: all 0.25s ease;
        }
        &.pull-up:hover {
            transform: translateY(-4px) scale(1.07);
        }
    }
    // Avatar Group Sizings
    .avatar:not(:first-child) {
        margin-left: -0.55rem;
    }
    .avatar-sm:not(:first-child) {
        margin-left: -0.4rem;
    }
    .avatar-lg:not(:first-child) {
        margin-left: -1rem;
    }
    .avatar-xl:not(:first-child) {
        margin-left: -1.5rem;
    }
}

.switch-badges {
    > .badge {
        border-radius: 40px;
        padding: 4px 12px;
        line-height: 16px;
        cursor: pointer;

        &.bg-primary {
            background-color: #e8e6f3 !important;
            color: $primary;
            border: 1px solid #e8e6f3;
        }

        &.bg-secondary {
            color: #5a5a5a;
            background-color: transparent !important;
            border: 1px solid #5a5a5a;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.65;
        }
    }
}

@media (max-width: 400px) {
    .matter-type-on-create {
        flex-direction: column;
        align-items: flex-start;
        .switch-badges {
            margin-bottom: 5px;
        }
    }
}

.qase-badges {
    .badge {
        border-radius: 8px;
        padding: 8px;
        line-height: 16px;
        font-weight: 500;

        &.bg-primary {
            background-color: #e8e6f3 !important;
            color: $primary;
            border: 1px solid #e8e6f3;
        }

        &.bg-secondary {
            color: #5a5a5a;
            background-color: transparent !important;
            border: 1px solid #5a5a5a;
        }
    }
}


.insurance-status-badges {
    .badge{
        border-radius: 4px;
        padding: 4px 6px;
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 16px;
        font-weight: 500;

        &.bg-success {
            background-color: #E2EEE2 !important;
            color: #0D7511;
            border: 1px solid #E2EEE2;
        }

        &.bg-secondary {
            color: #5A5A5A;
            background-color: #E8E8E8 !important;
            border: 1px solid #E8E8E8;
        }

        &.bg-danger{
            color: #C22725;
            background-color: #FBDDDC !important;
            border: 1px solid #FBDDDC;
        }
    }
}

.q-width-60 {
    width: 60px;
}

.q-width-130 {
    width: 130px;
}

.q-width-210 {
    width: 210px;
}

.q-min-height-300 {
    min-height: 300px;
}

.daterangepicker {
    // background-color: #f2f2f2;
    // box-shadow: 0px 2px 6px 2 rgba(0, 0, 0, 0.3);

    // &::before,
    // &::after {
    //     content: none;
    // }

    // .calendar-table {
    //     background-color: #f2f2f2;
    //     border-color: #f2f2f2;
    // }

    .ranges {
        li {
            color: #1b1b1f;
            font-size: 14px;
            font-weight: 500;
            &.active {
                background-color: #e8e6f3;
                color: #1b1b1f;
            }
        }
    }

    td {
        // .of {
        //     background-color: #f2f2f2;
        // }
        &.in-range {
            background-color: #e8e6f3;
        }
        &.active {
            background-color: #220c89;

            &:hover {
                background-color: #220c89;
            }
        }
    }
}
