.q-mb-4 {
    margin-bottom: 4px;
}

.q-mb-12 {
    margin-bottom: 12px;
}

.q-mb-14 {
    margin-bottom: 32px;
}

.q-mb-20 {
    margin-bottom: 20px;
}

.q-mb-25 {
    margin-bottom: 25px;
}

.q-mb-30 {
    margin-bottom: 30px;
}

.q-mb-32 {
    margin-bottom: 32px;
}

.q-mb-35 {
    margin-bottom: 35px;
}

.q-mb-38 {
    margin-bottom: 38px;
}

.q-mb-40 {
    margin-bottom: 40px;
}

.q-mb-48 {
    margin-bottom: 48px;
}

.q-mb-50 {
    margin-bottom: 50px;
}

.q-mb-52 {
    margin-bottom: 52px;
}

.q-mb-77 {
    margin-bottom: 77px;
}

.q-ml-42 {
    margin-left: 42px;
}

.q-pa-12 {
    padding: 12px;
}

.q-px-50 {
    padding-left: 50px;
    padding-right: 50px;
}

.q-ps-50 {
    padding-left: 50px;
}

.q-pe-50 {
    padding-right: 50px;
}

.q-pt-75 {
    padding-top: 75px;
}

@media (min-width: 768px) {
    .q-mb-md-52 {
        margin-bottom: 52px !important;
    }
}

.rounded-4 {
    border-radius: 0.5rem;
}
