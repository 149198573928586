@import '~react-toastify/dist/ReactToastify.css';

$border-radius: 0.357rem !default;
$theme-dark-card-bg: #283046 !default;
$theme-dark-body-color: #b4b7bd !default;

$primary: #220d89;
$secondary: #82868b !default;
$success: #28c76f !default;
$info: #00cfe8;
$warning: #ff9f43;
$danger: #ea5455;
$black: #22292f !default;

$colors: (
    'white': (
        'base': #ffffff,
    ),
    'black': (
        'base': #000000,
    ),
    'dark': (
        'base': #4b4b4b,
        'darken-1': #343434,
        'darken-2': #1e1e1e,
        'darken-3': #626262,
    ),
    'light': (
        'base': #f6f6f6,
    ),
    'primary': (
        'lighten-4': lighten($primary, 20%),
        'lighten-3': lighten($primary, 15%),
        'lighten-2': lighten($primary, 10%),
        'lighten-1': lighten($primary, 5%),
        'base': $primary,
        'darken-1': darken($primary, 5%),
        'darken-2': darken($primary, 10%),
        'darken-3': darken($primary, 15%),
        'darken-4': darken($primary, 20%),
    ),
    'secondary': (
        'lighten-4': lighten($secondary, 20%),
        'lighten-3': lighten($secondary, 15%),
        'lighten-2': lighten($secondary, 10%),
        'lighten-1': lighten($secondary, 5%),
        'base': $secondary,
        'darken-1': darken($secondary, 5%),
        'darken-2': darken($secondary, 10%),
        'darken-3': darken($secondary, 15%),
        'darken-4': darken($secondary, 20%),
    ),
    'success': (
        'lighten-4': lighten($success, 20%),
        'lighten-3': lighten($success, 15%),
        'lighten-2': lighten($success, 10%),
        'lighten-1': lighten($success, 5%),
        'base': $success,
        'darken-1': darken($success, 5%),
        'darken-2': darken($success, 10%),
        'darken-3': darken($success, 15%),
        'darken-4': darken($success, 20%),
    ),
    'info': (
        'lighten-4': lighten($info, 20%),
        'lighten-3': lighten($info, 15%),
        'lighten-2': lighten($info, 10%),
        'lighten-1': lighten($info, 5%),
        'base': $info,
        'darken-1': darken($info, 5%),
        'darken-2': darken($info, 10%),
        'darken-3': darken($info, 15%),
        'darken-4': darken($info, 20%),
    ),
    'warning': (
        'lighten-4': lighten($warning, 20%),
        'lighten-3': lighten($warning, 15%),
        'lighten-2': lighten($warning, 10%),
        'lighten-1': lighten($warning, 5%),
        'base': $warning,
        // #FFAC5D
        'darken-1': darken($warning, 5%),
        'darken-2': darken($warning, 10%),
        'darken-3': darken($warning, 15%),
        'darken-4': darken($warning, 20%),
    ),
    'danger': (
        'lighten-4': lighten($danger, 20%),
        'lighten-3': lighten($danger, 15%),
        'lighten-2': lighten($danger, 10%),
        'lighten-1': lighten($danger, 5%),
        'base': $danger,
        'darken-1': darken($danger, 5%),
        'darken-2': darken($danger, 10%),
        'darken-3': darken($danger, 15%),
        'darken-4': darken($danger, 20%),
    ),
);

.Toastify__toast {
    background-color: #fff;
    border-radius: 0.286rem;
    box-shadow: 0 2px 20px 0 rgba(#22292f, 0.08);
    padding: 1rem;
    .Toastify__toast-body,
    .Toastify__close-button {
        color: #6e6b7b;
    }

    .toastify-header {
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-wrapper {
            display: flex;
            align-items: center;

            .avatar svg {
                height: 0.85rem;
                width: 0.85rem;
            }
        }
        .toast-title {
            color: #5e5873;
            font-weight: 600;
            margin-left: 0.75rem;
            margin-bottom: 0;
        }
    }

    .toastify-body {
        color: #6e6b7b;
        padding-left: 2.3rem;
        font-size: 0.85rem;
        //font-family: $font-family-sans-serif;
    }

    .Toastify__close-button {
        opacity: 1;
        margin-top: 1px;
        margin-left: 0.5rem;
        svg {
            height: 0.85rem;
            width: 0.85rem;
            fill: #5e5873;
        }
        &:hover svg {
            fill: #5e5873;
        }
        &:focus {
            outline: 0;
        }
    }

    &.Toastify__toast--default {
        .toast-title {
            color: #220d89;
        }
    }

    &.Toastify__toast--error {
        .toast-title {
            color: #ea5455;
        }
        .Toastify__progress-bar {
            background-color: #ea5455;
        }
    }

    @each $color_name, $color in $colors {
        @each $color_type, $color_value in $color {
            @if $color_type== 'base' {
                &.Toastify__toast--#{$color_name} {
                    .toast-title {
                        color: $color_value;
                    }
                    .Toastify__progress-bar {
                        background-color: $color_value;
                    }
                }
            }
        }
    }
}

// Progress Bar
.Toastify__progress-bar {
    bottom: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    &.Toastify__progress-bar--default {
        background: #220d89;
    }
}

// Dark Layout
.dark-layout {
    .Toastify__toast {
        background-color: $theme-dark-card-bg;
        box-shadow: 0 2px 20px 0 rgba($black, 0.3);
        .Toastify__toast-body,
        .Toastify__close-button {
            color: $theme-dark-body-color;
        }

        .toastify-body {
            color: $theme-dark-body-color;
        }

        .Toastify__close-button {
            svg {
                fill: $theme-dark-body-color;
            }
            &:hover svg {
                fill: #5e5873;
            }
        }
    }
}

// Media Queries
@media (max-width: 480px) {
    .Toastify__toast-container {
        .Toastify__toast + .Toastify__toast {
            margin-top: 1rem;
        }
    }
}
