.offcanvas {
    .offcanvas-header {
        padding: 22px 24px;

        .offcanvas-title {
            font-size: 24px;
            line-height: 32px;
            font-weight: 400;
        }
    }
}
