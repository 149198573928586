$chevron-left: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-right: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-up: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E";
$chevron-down: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E";

.qase-table {
    thead {
        th {
            font-size: 11px;
            text-transform: uppercase;
            color: #5c5c5c;
        }
    }
    tbody {
        border-top: 0 !important;
        tr {
            .show-hover {
                visibility: hidden;
            }
            &:hover {
                .show-hover {
                    visibility: visible;
                }
            }

            .details-control {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background-color: rgba(216, 216, 216, 0.3);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            td {
                padding: 15px 8px;
                vertical-align: middle;

                .btn-link {
                    text-decoration: none;
                    color: #333333;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            &.expanded {
                background-color: #f2f2f2;

                &:hover {
                    background-color: #f2f2f2 !important;
                    td {
                        background-color: #f2f2f2 !important;
                    }
                }
            }

            .form-control {
                border-color: #e8e8e8;

                &:focus {
                    border-color: #bbbbbb;
                    box-shadow: none;
                }
            }
        }
    }

    &.align-top {
        tbody {
            td {
                vertical-align: top;
            }
        }
    }
}

.no-data-found {
    height: 280px;
}

.matters-table {
    vertical-align: middle;

    thead {
        th {
            font-size: 11px;
            text-transform: uppercase;
            color: #333333;
            font-weight: 500;
            // opacity: 0.6;
            //font-size: 14px;
        }

        th:first-child {
            padding-left: 36px;
        }
    }

    tbody {
        border-top: 0 !important;

        td {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        td:first-child {
            padding-left: 36px;
        }

        .table-avatar {
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(34, 13, 137, 0.1);
            border-radius: 50%;
        }

        .action-btn {
            font-size: 14px;
            color: #000000;
            background-color: rgba($color: $primary, $alpha: 0.1);
            border: none;
            line-height: 16px;
            padding: 12px;
            border-radius: 7px;

            &.action-dropdown {
                padding-right: 36px;
                background-image: url(str-replace(
                    str-replace($chevron-down, 'currentColor', #000000),
                    '#',
                    '%23'
                ));
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                background-size: 16px 12px;
            }

            &:active,
            &:focus,
            &:hover {
                box-shadow: none !important;
                outline: none !important;
            }
        }

        .file-status-btn {
            font-size: 14px;
            color: #000000;
            padding: 12px 12px;
            line-height: 16px;
            width: 100px;
            border-radius: 7px;

            &.shared {
                background-color: rgba($color: #c3efcd, $alpha: 0.8);
                border-color: rgba($color: #c3efcd, $alpha: 0.8);
            }

            &.not-shared {
                background-color: rgba($color: #efc3c3, $alpha: 0.8);
                border-color: rgba($color: #efc3c3, $alpha: 0.8);
            }
        }

        .no-files-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
}

.leads-table-wrapper,
.table-min-width {
    .table-responsive {
        min-height: 500px;
    }
}

.table-pagination-wrapper {
    select.form-select {
        border-radius: 6px;
        border-color: rgba(0, 0, 0, 0.08);
        font-size: 12px;
        color: #8083a3;
        padding-top: 11px;
        padding-bottom: 11px;
        background-image: url(str-replace(
            str-replace($chevron-down, 'currentColor', #8083a3),
            '#',
            '%23'
        ));
    }

    .page-item {
        .page-link {
            font-size: 12px;
            font-weight: 500;
            color: #171721;
            padding: 11px 17px;
            border: 0;
            background-color: transparent;
            outline: none !important;
            box-shadow: none !important;
        }

        &.active {
            .page-link {
                font-weight: 700;
                color: #ffffff;
                border-radius: 8px;
                background-color: $primary;
                z-index: 0;
            }
        }

        &.next-item {
            border: 1px solid rgba($color: #000000, $alpha: 0.08);
            border-radius: 8px;
            margin-left: 5px;

            .page-link {
                color: #8083a3;
                font-weight: 500;
                padding-right: 39px;
                background-image: url(str-replace(
                    str-replace($chevron-right, 'currentColor', #8083a3),
                    '#',
                    '%23'
                ));
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                background-size: 16px 12px;
            }
        }

        &.previus-item {
            border: 1px solid rgba($color: #000000, $alpha: 0.08);
            border-radius: 8px;
            margin-right: 5px;

            .page-link {
                color: #8083a3;
                font-weight: 500;
                padding-left: 39px;
                background-image: url(str-replace(
                    str-replace($chevron-left, 'currentColor', #8083a3),
                    '#',
                    '%23'
                ));
                background-repeat: no-repeat;
                background-position: left 0.75rem center;
                background-size: 16px 12px;
            }
        }
    }
}

.sticky-table {
    overflow: auto;
    // min-height: 100px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #f5f2f7;
        border-radius: 0px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #1b1b1f26;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $primary;
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 1;

        th {
            font-size: 14px;
            //text-transform: uppercase;
            color: #333333;
            word-break: keep-all;
            white-space: nowrap;
            background-color: #f8f8f8;
        }
    }
    tbody {
        border-top: 0 !important;
        word-break: keep-all;
        white-space: nowrap;
        font-size: 14px;
        tr {
            .show-hover {
                visibility: hidden;
            }
            &:hover {
                .show-hover {
                    visibility: visible;
                }
            }

            .details-control {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                background-color: rgba(216, 216, 216, 0.3);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            td {
                padding: 10px 8px;
                vertical-align: middle;

                .btn-link {
                    text-decoration: none;
                    color: #333333;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            &.expanded {
                background-color: #f2f2f2;

                &:hover {
                    background-color: #f2f2f2 !important;
                    td {
                        background-color: #f2f2f2 !important;
                    }
                }
            }

            .form-control {
                border-color: #e8e8e8;

                &:focus {
                    border-color: #bbbbbb;
                    box-shadow: none;
                }
            }
        }
    }
}
