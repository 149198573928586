.anonymous-layout {
    .anonymouseNavbar {
        background-color: $white;
        box-shadow: 0 2px 14px 10px rgba(0, 0, 0, 0.05);

        .navbar-brand {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-right: 2rem;
            font-size: 0;
            display: flex;
            align-items: center;

            img {
                max-height: 52px;
            }
        }

        .navbar-nav {
            .nav-link {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}
