.rate-wrapper {
    .pagination {
        .page-item {
            margin-right: 15px;
            .page-link {
                padding: 5px 15px;
                border-radius: 4px;
                background-color: #f2f2f2;
                border-color: #f2f2f2;
                color: #555555;
            }

            &:last-child {
                margin-right: 0;
            }

            &.active {
                .page-link {
                    background-color: $primary;
                    border-color: $primary;
                    color: #ffffff;
                    outline: none;
                    box-shadow: none;
                }
            }

            @media (min-width: 768px) {
                .page-link {
                    padding: 10px 30px;
                }
            }
        }
    }
}
