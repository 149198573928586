.user-card {
    background-color: $primary;
    border-radius: 0;
    height: 100%;
    border: 0;
    color: #fff;

    &.bg-gray {
        background-color: #f5f7f9;
        color: #220d89;
    }

    .card-body {
        padding: 40px 30px;
    }

    .user-image-wrapper {
        margin-bottom: 20px;

        img {
            max-height: 72px;
        }
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
    }
}

.referral-card {
    border: 0;
    border-radius: 0;
    background-color: #e9e7f3;
    height: 100%;

    .card-body {
        padding: 33px 26px;
    }
}

.recent-messages-card {
    border: 0;
    border-radius: 0;

    .card-header {
        background-color: #ffffff;
        border-bottom: 0;
        padding: 21px 21px 17px 21px;

        h5 {
            font-size: 16px;
            font-weight: 600;
        }

        a {
            font-size: 14px;
        }
    }

    .card-body {
        padding: 0 30px 30px;

        .message-item {
            img {
                max-width: 53px;
            }

            .author {
                font-weight: 600;
                color: #000000;
            }

            .message-time {
                float: right;
                color: rgba($color: #000000, $alpha: 0.6);
                font-weight: 400;
                font-size: 12px;
            }
        }

        .message-chat-wrapper {
            height: 360px;
        }
    }
}

.timeline-card {
    border: 0;
    border-radius: 0;

    .card-header {
        background-color: #ffffff;
        border-bottom: 0;
        padding: 21px 21px 17px 21px;

        h5 {
            font-size: 16px;
            font-weight: 600;
        }

        a {
            font-size: 14px;
        }
    }

    .card-body {
        padding: 0 30px 30px;

        .timeline-avatar {
            height: 46px;
            width: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(34, 13, 137, 0.1);
            border-radius: 50%;

            img {
                width: 18px;
                height: 16px;
            }
        }

        .title {
            font-size: 14px;
            color: #000000;
        }

        .timeline-date {
            font-size: 14px;
            color: rgba($color: #000000, $alpha: 0.6);
        }

        .timeline-items-wrapper {
            height: 360px;
        }
    }
}

.profile-card {
    border: 0;
    border-radius: 0;
}

.note-card {
    border-radius: 0;
    border: 1px solid #eaeaea;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);

    .card-body {
        padding: 27px 76px 62px 22px;

        .share-btn {
            position: absolute;
            top: -1px;
            right: -1px;
            padding: 8px 9px 11px 9px;
            border: 1px solid rgba($color: #000000, $alpha: 0.1);
            border-radius: 0px 0px 0px 10px;
            outline: none !important;
            box-shadow: none !important;
        }

        .notes-time {
            position: absolute;
            //width: 100%;
            right: 22px;
            bottom: 23px;
        }
    }
}

.calendar-card {
    // box-shadow: 0px 0px 10px rgba(34, 13, 137, 0.4);
    box-shadow: 0 1px 4px 0 rgba(40, 40, 40, 0.3);
    border-radius: 8px;
    border-width: 0;

    .card-body {
        padding: 16px;
        // text-align: center;
        min-width: 96px;

        img {
            width: 24px;
        }
    }

    .card-footer {
        border: 0;
        background-color: #f2f2f2;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 11px;
        color: #a0aab4;

        &:hover {
            -moz-box-shadow: 0px -3px 0px #8891f7;
            -webkit-box-shadow: 0px -3px 0px #8891f7;
            box-shadow: 0px -3px 0px #8891f7;
        }
    }

    &.active {
        background-color: #e2eee2;
        box-shadow: none;

        .card-footer {
            background-color: #00cc99;
            color: #ffffff;
        }
    }
}

.alert-info-qase {
    color: #333333;
    background-color: #dfedff;
    border-color: #dfedff;

    .btn-close {
        padding: 0;
        height: 10px;
        width: 10px;
        top: 10px;
        right: 10px;
    }
}

.practice-card {
    .check-icon {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}

.legal-issue-card {
    border: 2px solid #005454;

    &.selected {
        background-color: #005454;
        color: #fff;
    }
}
