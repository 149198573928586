.global-search {
    .search-input-group {
        .input-group-text {
            background-color: #e8e8e8;
            border-color: #e8e8e8;
            border-right: 0;
        }

        .search-input {
            background-color: #e8e8e8;
            border-color: #e8e8e8;
            border-left: 0;
        }
    }

    .suggestions-list {
        position: absolute;
        top: 60px;
        left: 116px;
        width: calc(100% - 116px);
        width: 100%;
        background-color: #fff;
        min-height: 100vh;
        list-style: none;
        padding: 16px;
    }
}
.global-search-modal {
    top: 60px;
    left: 116px;
    width: calc(100% - 116px);
    height: calc(100% - 60px);

    .modal-fullscreen {
        width: 100%;
    }

    .modal-header {
        border-bottom: none;
    }

    .sticky-table {
        .status-btn,
        .search-status-btn {
            font-size: 12px;
            line-height: 16px;
            border: none;
        }
    }
}
